import React, { Component } from 'react';
import { Button } from 'reactstrap';
import helper from '../services/helper';
import FormSchema from '../schema';
import Local from '../services/local';
import Loader from './Loader';
export default class FormCtrl extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: this.props.query.embed ? JSON.parse(this.props.query.embed) : null,
            pageInfo: null,
            error: null,
            loading: true
        }
    }
    componentDidMount() {
        this.loadData();
    }
    componentWillReceiveProps(next) {
        this.loadData(next);
    }
    async loadData(props) {
        if (!props) props = this.props;
        let pageInfo = await helper.getPage(props.query.page);
        console.log('PAGE INFO', pageInfo);
        this.setState({
            pageInfo,
            mode: props.query.mode
        });
        if (props.query.mode === 'edit') {
            if (!props.query.id) {
                return this.setState({ error: 'Không có thông tin để tải dữ liệu' })
            }
            let rs = await helper.callPageApi(pageInfo, pageInfo.read, { queryInput: JSON.stringify({ id: props.query.id }) });
            let data = rs.data[0];
            if (props.query.embed) {
                Object.assign(data, JSON.parse(props.query.embed));
            }
            this.setState({ data })
        }
    }
    async onSubmit() {
        this.onButtonClick();
    }
    async onButtonClick(btnInfo) {
        if (!btnInfo) {
            for (var i = 0; i < this.state.pageInfo.buttons.length; i++) {
                if (this.state.pageInfo.buttons[i].mode === this.props.query.mode) {
                    btnInfo = this.state.pageInfo.buttons[i];
                    break;
                }
            }
        }
        if (btnInfo) {
            try {
                if (btnInfo.type === 'submit' && btnInfo.action === 'api') {
                    if (this.formRef) {
                        let error = this.formRef.checkError();
                        if (error >= 0) return helper.alert(`Dữ liệu chưa đúng, kiểm tra lại thông tin ${this.state.pageInfo.schema[error].name}`);
                    }
                }
                let data = Object.assign({}, this.state.data);
                if (btnInfo.confirm) {
                    let confirmText = btnInfo.confirm;
                    for (var f in data) {
                        confirmText = helper.replaceAll(confirmText, '#' + f + '#', data[f]);
                    }
                    let rs = await helper.confirm(confirmText);
                    if (!rs) return;
                }
                if (this.props.query.embed && btnInfo.embedUrl) {
                    data = Object({}, data, JSON.stringify(this.props.query.embed));
                }
                console.log(data);
                for (var i in data) {
                    console.log(i);
                    if (i === `undefined`) {
                        delete data[i];
                    }
                }
                let response = await helper.callPageApi(this.state.pageInfo, btnInfo.api, data);
                await helper.alert(response.message || 'Thành công');
                if (btnInfo.backOnDone) {
                    if (this.props.onClose) {
                        this.props.onClose();
                    } else {
                        window.history.back();
                    }

                }
            } catch (err) {
                helper.alert(err.message);
            }
        } else {
            helper.alert('Không có nút bấm');
        }
    }
    render() {
        if (this.state.mode === 'edit' && !this.state.data) return <Loader />;
        if (this.state.error) return (<p className='text-danger'>{this.state.error}</p>)
        if (!this.state.pageInfo) return null;
        return (
            <FormSchema
                ref={ref => { this.formRef = ref; }}
                schema={this.state.pageInfo.schema}
                data={this.state.data || {}}
                onChange={data => {
                    this.setState({ data })
                }}
                onSubmit={this.onSubmit.bind(this)}>
                {this.state.pageInfo.buttons.map((item, index) => {
                    if (this.state.mode === item.mode) {
                        let url = '';
                        var i = 0;
                        switch (item.action) {
                            case 'url':
                                url = item.url.replace('$', this.state.data);
                                for (i in this.state.data) {
                                    url = helper.replaceAll(url, '#' + i + '#', this.state.data[i]);
                                }
                                for (i in this.query) {
                                    url = helper.replaceAll(url, '@' + i + '@', this.props.query[i]);
                                }
                                return <a key={index} href={url} className={`btn btn-${item.color} mr-1`}><i className={item.icon} /> {item.title}</a>
                            case 'api':
                            case 'formModal':
                                return <Button key={index} className='mr-1' color={item.color} onClick={() => { this.onButtonClick(item) }} ><i className={item.icon} /> {item.title}</Button>
                            case 'report':
                                url = item.url.replace('$', this.state.data);
                                for (i in this.state.data) {
                                    url = helper.replaceAll(url, '#' + i + '#', this.state.data[i]);
                                }
                                for (i in this.query) {
                                    url = helper.replaceAll(url, '@' + i + '@', this.props.query[i]);
                                }
                                url += '&accesstoken=' + Local.get('session');
                                return <a key={index} href={url} className={`btn btn-${item.color} mr-1`}><i className={item.icon} /> {item.title}</a>
                            default:
                                return null;
                        }
                    }
                    return null;
                })}
            </FormSchema>

        );
    }
}
