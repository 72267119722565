import React, { Component } from 'react';
import Widgets from './Widgets';
import { FormGroup, Label, Col } from 'reactstrap'
class Base extends Component {
    error() {
        if (this.isInvalid()) {
            return <p className='text-danger'><b>{this.props.schema.name} là trường dữ liệu bắt buộc!</b></p>
        }
    }

    isInvalid() {
        if (this.props.schema.required && (this.props.value === null || this.props.value === undefined || this.props.value === '')) return true;
    }

    render() {
        let Widget = Widgets[this.props.schema.widget];
        if (!Widget) {
            return <p>Invalid type {this.props.schema.widget}</p>
        }
        return <FormGroup row>
            <Col md='3' className='form-label-horizontal'>
                <Label><b>{this.props.schema.name} {this.props.schema.required ? '(*)' : ''}</b></Label>
            </Col>
            <Col md='9'>
                <Widget
                    onChange={this.props.onChange}
                    value={this.props.value === undefined ? this.props.schema.default : this.props.value}
                    schema={this.props.schema}
                    disabled={this.props.schema.disabled}
                    invalid={this.isInvalid()} />
                {this.error()}
            </Col>
        </FormGroup>

    }
}

export default Base;