import request from "./request";
import local from "./local";
import locations from "../locations.json";
import queryString from "qs";
import _ from "lodash";
import Local from "./local";
import configureStore from "../store";
let helper = {};
helper.replaceAll = (str, search, replacement) => {
  if (!str) str = "";
  return str.replace(new RegExp(search, "g"), replacement);
};
helper.reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};
helper.showLoading = () => {
  let store = configureStore();
  store.dispatch({ type: "SHOW_LOADING" });
};

helper.hideLoading = () => {
  let store = configureStore();
  store.dispatch({ type: "HIDE_LOADING" });
};
helper.getLocationName = (type, id) => {
  let arr = locations[type];
  for (var i = 0; i < arr.length; i++) {
    if (arr[i].id === id) return arr[i].name;
  }
  return null;
};
helper.getApiByName = (apis, name) => {
  for (var i = 0; i < apis.length; i++) {
    if (apis[i].name === name) return apis[i];
  }
  return null;
};
helper.showCustomModal = opts => {
  let { type, props } = opts;
  let store = configureStore();
  return new Promise((resolve, reject) => {
    store.dispatch({
      type: "PUSH_MODAL",
      data: {
        type,
        props,
        cb: rs => {
          resolve(rs);
        }
      }
    });
  });

  // alert(content);
};
helper.setUserInfo = info => {
  let store = configureStore();
  store.dispatch({
    type: "SET_USER_INFO",
    data: info
  });
};
helper.alert = content => {
  return new Promise((resolve, reject) => {
    let store = configureStore();
    store.dispatch({
      type: "PUSH_MODAL",
      data: {
        type: "message",
        content,
        cb: rs => {
          resolve();
        }
      }
    });
  });
};
helper.confirm = content => {
  return new Promise((resolve, reject) => {
    let store = configureStore();
    store.dispatch({
      type: "PUSH_MODAL",
      data: {
        type: "confirm",
        content,
        cb: rs => {
          resolve(rs === 1);
        }
      }
    });
  });
};
helper.callPageApi = (page, name, data) => {
  let api = helper.getApiByName(page.apis, name);
  let input = _.clone(data),
    url = api.url;
  switch (api.method) {
    case "GET":
      for (var i in data) {
        if (data[i] === undefined) delete data[i];
      }
      input = Object.assign(
        {},
        {
          page: page.id,
          api: api.name
        },
        data
      );
      url += `?${queryString.stringify(input)}`;
      break;
    case "PATCH":
    case "DELETE":
      if (api.type === "update") {
        url += `/${data.id}?${queryString.stringify({
          page: page.id,
          api: api.name
        })}`;
        delete input.id;
      }
      break;
    default:
      url += `?${queryString.stringify({ page: page.id, api: api.name })}`;
      break;
  }
  return request.request(`${url}`, input, {}, api.method);
};
helper.getReportUrl = (page, name, data) => {
  let api = helper.getApiByName(page.apis, name);
  let input = _.clone(data),
    url = api.url;
  switch (api.method) {
    case "GET":
      for (var i in data) {
        if (data[i] === undefined) delete data[i];
      }
      input = Object.assign(
        {},
        {
          page: page.id,
          api: api.name,
          accesstoken: Local.get("session") || "customer"
        },
        data
      );
      url += `?${queryString.stringify(input)}`;
      break;
    default:
      break;
  }
  console.log("report url", url);
  return url;
};
helper.getApiUrl = (page, name) => {
  let api = null;
  for (var i = 0; i < page.apis.length; i++) {
    if (page.apis[i].name === name) {
      api = page.apis[i];
      break;
    }
  }
  switch (api.action) {
    default:
      return `/${api.controller}/${api.action}?page=${page.id}&api=${name}`;
  }
};
helper.getPage = async id => {
  id = Number(id);
  let meta = local.get("meta");
  let pages = meta.pages;
  for (var i = 0; i < pages.length; i++) {
    if (pages[i].id === id) {
      if (!Array.isArray(pages[i].buttons)) pages[i].buttons = [];
      return pages[i];
    }
  }
};
export default helper;
